/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, Grid, Row, styled, up, css, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import Button from '_platform/src/components/Button/Button';
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/Background-v2.jpg';
import bgImage2x from './images/Background-v2@2x.jpg';
import logo from './images/Patties-Extravaganza@2x.png';
import text from './images/Text@2x.png';
import WelcomeBottom from './images/welcome-bottom@1x.png';
import Patties from './images/Patties@2x.png';
import Leader from './images/leader_ribbon_col@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: #131d28;
  background-image: url(${bgImage1x});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #131d28;
    background-image: url(${bgImage2x});
  }

  padding-bottom: 1em;

  ${up(
    'lg',
    css`
      padding-bottom: 5em;
    `
  )};
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0.1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 370px;
  }
`;

const IntroContainer = styled.div`
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  padding: 2.5rem;
  h2 {
    font-size: 30px;
  }
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }

  ${up(
    'lg',
    css`
      margin-top: 10rem;
    `
  )};
`;

const WelcomeContainer = styled.div`
  max-width: 415px;
  border-radius: 15px;
  padding-top: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  background: transparent
    radial-gradient(
      circle at 50% 35%,
      rgba(242, 254, 255, 1) 0%,
      rgba(236, 251, 252, 1) 30%,
      rgba(128, 195, 200, 1) 70%,
      rgba(83, 185, 193, 1) 100%
    )
    0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 20px #39a0a846;
  background: url(${WelcomeBottom}) bottom no-repeat;

  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.8rem 0 0;
  }
  li {
    text-align: center;
    padding-bottom: 0.2rem;

    &:nth-child(1) {
      padding-right: 10px;
    }

    &:nth-child(2) {
      padding-left: 10px;
    }
  }

  .register-button {
    border-radius: 5px;
    background-color: #ed1844;
    color: #fff;
  }
`;

const LoginContainer = styled.div`
  padding: 2rem;
  ${up(
    'lg',
    css`
      padding: 2rem 5rem;
    `
  )};

  max-width: 415px;
  border-radius: 15px;
  color: #1c355e;
  background: rgb(242, 254, 255);
  background: radial-gradient(
    circle,
    rgba(242, 254, 255, 1) 0%,
    rgba(98, 191, 199, 1) 100%,
    rgba(83, 185, 193, 1) 100%
  );
  box-shadow: 0px 20px 20px #39a0a846;

  h4 {
    font-style: italic;
  }

  label {
    font-size: 14px;
    font-weight: bold;
  }

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
      color: #1c355e;

      &:hover {
        text-decoration: none;
      }
    }
  }

  button {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 100%;

  img {
    width: 90%;
    max-width: 357px;
    height: auto;
  }
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} sm={10} md={8} lg={5} className="text--center">
                <WelcomeContainer>
                  <LoginLogo className="text--center">
                    <img
                      src={logo}
                      alt={
                        settings.settingsApp && settings.settingsApp.siteName
                          ? settings.settingsApp.siteName
                          : 'ProgramName'
                      }
                    />
                  </LoginLogo>
                  <TextContainer className="text--center">
                    <img
                      src={text}
                      alt="Puchase participating Patties and Leader products to be rewarded!"
                    />
                  </TextContainer>
                  <div className="button-container text--center">
                    <Button
                      as={Link}
                      className="register-button"
                      to="/register/"
                    >
                      Register Here
                    </Button>
                    <ul>
                      <li>
                        <img src={Patties} alt="Patties" width="150px" />
                      </li>
                      <li>
                        <img src={Leader} alt="Leader" width="150px" />
                      </li>
                    </ul>
                  </div>
                </WelcomeContainer>
              </Col>
              <Col xs={12} sm={10} md={8} lg={5}>
                <LoginContainer>
                  <h4 className="text--center">
                    Already registered?
                    <br />
                    Login below
                  </h4>
                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </LoginContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
